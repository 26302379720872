// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-de-dienstleistungen-js": () => import("./../../../src/pages/de/dienstleistungen.js" /* webpackChunkName: "component---src-pages-de-dienstleistungen-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-katalog-js": () => import("./../../../src/pages/de/katalog.js" /* webpackChunkName: "component---src-pages-de-katalog-js" */),
  "component---src-pages-de-kontact-js": () => import("./../../../src/pages/de/kontact.js" /* webpackChunkName: "component---src-pages-de-kontact-js" */),
  "component---src-pages-de-kuche-js": () => import("./../../../src/pages/de/kuche.js" /* webpackChunkName: "component---src-pages-de-kuche-js" */),
  "component---src-pages-en-catalog-js": () => import("./../../../src/pages/en/catalog.js" /* webpackChunkName: "component---src-pages-en-catalog-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-kitchens-js": () => import("./../../../src/pages/en/kitchens.js" /* webpackChunkName: "component---src-pages-en-kitchens-js" */),
  "component---src-pages-en-services-js": () => import("./../../../src/pages/en/services.js" /* webpackChunkName: "component---src-pages-en-services-js" */),
  "component---src-pages-hr-catalog-js": () => import("./../../../src/pages/hr/catalog.js" /* webpackChunkName: "component---src-pages-hr-catalog-js" */),
  "component---src-pages-hr-contact-js": () => import("./../../../src/pages/hr/contact.js" /* webpackChunkName: "component---src-pages-hr-contact-js" */),
  "component---src-pages-hr-index-js": () => import("./../../../src/pages/hr/index.js" /* webpackChunkName: "component---src-pages-hr-index-js" */),
  "component---src-pages-hr-kitchens-js": () => import("./../../../src/pages/hr/kitchens.js" /* webpackChunkName: "component---src-pages-hr-kitchens-js" */),
  "component---src-pages-hr-services-js": () => import("./../../../src/pages/hr/services.js" /* webpackChunkName: "component---src-pages-hr-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-katalog-js": () => import("./../../../src/pages/katalog.js" /* webpackChunkName: "component---src-pages-katalog-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kuchyne-js": () => import("./../../../src/pages/kuchyne.js" /* webpackChunkName: "component---src-pages-kuchyne-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-katalog-js": () => import("./../../../src/pages/pl/katalog.js" /* webpackChunkName: "component---src-pages-pl-katalog-js" */),
  "component---src-pages-pl-kontakt-js": () => import("./../../../src/pages/pl/kontakt.js" /* webpackChunkName: "component---src-pages-pl-kontakt-js" */),
  "component---src-pages-pl-kuchnie-js": () => import("./../../../src/pages/pl/kuchnie.js" /* webpackChunkName: "component---src-pages-pl-kuchnie-js" */),
  "component---src-pages-pl-uslugi-js": () => import("./../../../src/pages/pl/usługi.js" /* webpackChunkName: "component---src-pages-pl-uslugi-js" */),
  "component---src-pages-sluzby-js": () => import("./../../../src/pages/sluzby.js" /* webpackChunkName: "component---src-pages-sluzby-js" */)
}

